<template>
  <div>
    <!-- 1 -->
    <section class="main">
      <div class="d-flex flex-column flex-md-row mb-5">
        <div
          class="col-12 col-md-5 d-flex flex-column justify-content-center align-items-start p-3"
        >
          <h1 class="f-secondary t-secondary text-gradient p-2">
            Take your career to new heights
          </h1>
          <div class="f-primary p-2 line-height-30 mb-3">
            We know some healthcare roles come with strict language and training
            requirements, yet finding courses to help you meet these
            requirements can be a long and stressful process. That’s why we
            connect you with accredited training providers to help you upskill
            and expand your learning in the simplest way, and it’s all built
            into our core platform.
          </div>
        </div>
        <div class="col-12 col-md-7 p-0">
          <img src="../../../public/img/stm/job-banner.png" class="img-fluid" />
        </div>
      </div>
    </section>

    <!-- 2 -->
    <section class="main container-fluid">
      <div
        class="d-flex justify-content-center align-items-center flex-column mb-3"
      ></div>
      <!-- Starts -->
      <div class="row my-5">
        <!-- Left -->
        <div class="col-md-6 text-start order-md-1">
          <div>
            <h5 class="text-second text-uppercase mb-3">Trusted providers</h5>
            <h2 class="f-secondary text-gradient-2 font-40 mb-3">
              Our commitment to quality
            </h2>
            <p class="w-80">
              We’ve partnered with leading language training schools and
              universities to bring you all the resources you need to develop
              your skills. Each course provider is accredited and recognised
              within their individual specialism, so you can be sure you’ll
              receive the knowledge and training required to deliver the highest
              quality care in real-world settings.
            </p>
          </div>
        </div>

        <!-- Right -->
        <div class="col-md-6 text-center order-md-2 mb-5 mb-md-0">
          <img
            class="img-fluid border-radius-40"
            src="../../../public/img/stm/work5.png"
            alt="Image Description"
          />
        </div>
      </div>
      <!-- Ends -->
    </section>
    <!-- 3 -->
    <FutureSection />
    <NewFooter />
    <ScrollToTop />
  </div>
</template>

<script>
import FutureSection from "./FutureSection.vue";
import NewFooter from "./NewFooter.vue";
import ScrollToTop from "../../components/reusable/ScrollToTop.vue";
import { mapActions } from "vuex";

export default {
  components: {
    FutureSection,
    NewFooter,
    ScrollToTop,
  },
  methods: {
    ...mapActions(["scrollTop"]),
  },
  mounted() {
    this.scrollTop();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
