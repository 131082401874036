import { render, staticRenderFns } from "./ProfessionalDevelopmentView.vue?vue&type=template&id=65b7c5a9&scoped=true"
import script from "./ProfessionalDevelopmentView.vue?vue&type=script&lang=js"
export * from "./ProfessionalDevelopmentView.vue?vue&type=script&lang=js"
import style0 from "./ProfessionalDevelopmentView.vue?vue&type=style&index=0&id=65b7c5a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b7c5a9",
  null
  
)

export default component.exports